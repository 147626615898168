<template>
  <v-card>
    <v-card-title class="text-h6">{{ $t("labels.roll_stamp") }}</v-card-title>
    <v-card-text>
      {{ $t("messages.scan_stamp_code") }}
      <span class="error--text">({{ activeStamp - receiptQuantity }})</span>
    </v-card-text>

    <v-card-text>
      <v-text-field
        v-model="code"
        class="c-input-small"
        @keyup.enter="roll"
        :label="$t('labels.stamp_code')"
        :placeholder="$t('labels.stamp_code')"
        autofocus
        :disabled="activeStamp == receiptQuantity"
        dense
        single-line
        outlined
        clearable
        hide-details
        append-icon="mdi-qrcode-scan"
        @click:append="showQRCodeScan('code')"
      ></v-text-field>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancel">
        {{ $t("labels.cancel") }}
      </v-btn>
      <v-btn
        color="green darken-1"
        text
        @click="confirm"
        :disabled="activeStamp > receiptQuantity"
      >
        {{ $t("labels.confirm") }}
      </v-btn>
    </v-card-actions>

    <v-dialog v-model="qrScanDialog" max-width="100vw">
      <QRCodeScanner
        v-if="qrScanDialog"
        :name="qrScanType"
        @close="hideQRCodeScan"
        @onScanned="onQRScanned"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "RollGoodsUid",
  components: {
    QRCodeScanner: () => import("@/components/common/QRCodeScanner"),
  },
  props: {
    goods: {
      type: Object,
      default: () => {},
    },
    insertTracking: {
      type: String,
      default: () => null,
    },
    receiptQuantity: {
      type: Number,
      default: () => 0,
    },
    printQuantity: {
      type: Number,
      default: () => 0,
    },
  },
  data: () => ({
    code: null,
    isLoading: false,
    activeStamp: 0,
    qrScanDialog: false,
    qrScanType: null,
  }),
  mounted() {
    this.activeStamp = this.printQuantity;
  },
  methods: {
    async roll() {
      if (!this.code) {
        return false;
      }

      if (this.isLoading) {
        this.code = null;
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post(
          "/goods-receipt/v1/roll-goods-uid",
          {
            goods: this.goods,
            code: this.code,
            insertTracking: this.insertTracking,
          }
        );
        this.activeStamp = data;
        this.isLoading = false;
        this.code = null;
        document.getElementById("success_sound_player").play();
        if (this.activeStamp == this.receiptQuantity) {
          this.confirm();
        }
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
        this.code = null;
        document.getElementById("error_sound_player").play();
      }
    },
    cancel() {
      this.$emit("cancel", this.activeStamp);
    },
    confirm() {
      this.$emit("confirm", this.activeStamp);
    },

    showQRCodeScan(type) {
      this.qrScanDialog = true;
      this.qrScanType = type;
    },
    hideQRCodeScan() {
      this.qrScanDialog = false;
      this.qrScanType = null;
    },
    onQRScanned(input) {
      this[input.name] = input.value;
      this.roll();
    },
  },
};
</script>

<style scoped></style>
